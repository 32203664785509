import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";

const root = document.getElementById("root");

// Replace ReactDOM.render with createRoot
const rootElement = ReactDOM.createRoot(root);
rootElement.render(<App />);
