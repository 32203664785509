import React from "react";
import "./ConsultancyCardSkeleton.scss"; // Style with placeholder animations

export default function SkeletonConsultancyCard() {
  return (
    <div className="consultancy-card skeleton">
      <div className="row-1">
        <div className="image skeleton-box"></div>
        <div className="title">
          <div className="text skeleton-box"></div>
          <div className="other skeleton-box"></div>
        </div>
        <div className="sortlist-btn skeleton-box"></div>
      </div>
      <div className="row-2">
        <div className="other skeleton-box"></div>
      </div>
    </div>
  );
}
