import axios from "axios";
export const apiUrl = process.env.REACT_APP_API_URL;

// const myToken = localStorage.getItem("access-token");
export const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    // Authorization: `Bearer ${myToken}`,
  },
});
