import React, { useContext } from "react";
import "./Footer.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaXTwitter } from "react-icons/fa6";
import {
  SlSocialFacebook,
  SlSocialInstagram,
  SlSocialLinkedin,
  SlSocialYoutube,
} from "react-icons/sl";
import { PageContext } from "../../layouts/HomePage/HomeLayout";

function Footer() {
  const { currentPage } = useContext(PageContext);
  const footerLinks = [
    {
      title: "DISCOVER",
      links: [
        { url: "#", text: "Browse Consultancy" },
        { url: "#", text: "List Consultancy" },
        { url: "#", text: "How it Works" },
      ],
    },
    {
      title: "LEGAL NOTICE",
      links: [
        { url: "#", text: "Privacy Policy" },
        { url: "#", text: "Terms and Condition" },
        { url: "#", text: "Cookies Policy" },
      ],
    },
    {
      title: "PRODUCT FAMILY",
      links: [
        { url: "#", text: "Link 1" },
        { url: "#", text: "Link 2" },
      ],
    },
  ];

  const socialMediaIcons = [
    {
      name: "twitter",
      icon: <FaXTwitter />,
      link: "https://twitter.com/yourprofile",
    },
    {
      name: "youtube",
      icon: <SlSocialYoutube />,
      link: "youtube.com/",
    },
    {
      name: "linkedin",
      icon: <SlSocialLinkedin />,
      link: "https://www.linkedin.com/",
    },
    {
      name: "facebook",
      icon: <SlSocialFacebook />,
      link: "https://facebook.com/yourprofile",
    },
  ];

  return (
    <div className={`footer ${currentPage === "browse" ? "hidden" : ""}`}>
      <div className="footer-links">
        {footerLinks.map((column, index) => (
          <div className="link-column" key={index}>
            <h3>{column.title}</h3>
            <ul>
              {column.links.map((link, index) => (
                <li key={index}>
                  <Link to={link.url} target="_blank">
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="footer-socials">
        {/* social media links */}
        <div className="social-links">
          {socialMediaIcons.map((social) => (
            <a
              href={social.link}
              key={social.name}
              target="_blank"
              rel="noopener noreferrer"
            >
              {social.icon}
            </a>
          ))}
        </div>

        {/* copyright  */}
        <div className="copyright">
          <span>
            Copyright &#169; 2024 Many Consultancy. All rights reserved
          </span>
          {/* <span>Mahalaxmi Municipality-8, Lalitpur, Nepal</span> */}
        </div>
      </div>
    </div>
  );
}

export default Footer;
