import React, { useState, useEffect, useRef, useCallback } from "react";

const Dump = () => {
  const [posts, setPosts] = useState([]); // Stores post data
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current page
  const [loading, setLoading] = useState(false); // Shows loading spinner while fetching
  const [hasMore, setHasMore] = useState(true); // Determines if more data is available

  // Dropdown states for filters (these could represent simulated filters like user ID)
  const [selectedUser, setSelectedUser] = useState(""); // For example purposes

  const observer = useRef(); // Observer reference for infinite scrolling

  // Fetch data function
  const fetchData = async () => {
    setLoading(true);

    // Construct the API endpoint URL with query parameters
    let url = `https://jsonplaceholder.typicode.com/posts?_page=${currentPage}&_limit=1`;
    if (selectedUser) {
      url += `&userId=${selectedUser}`; // Apply selected user as a filter
    }

    try {
      const response = await fetch(url);
      const newPosts = await response.json();

      if (newPosts.length > 0) {
        setPosts((prev) => [...prev, ...newPosts]);
        setHasMore(newPosts.length > 0); // Check if more data is available
      } else {
        setHasMore(false); // Stop loading if no more data is found
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  // Load more data when user reaches end of list
  const lastPostRef = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPage) => prevPage + 1); // Increase page count
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  // Fetch initial data and load more on page update or filter change
  useEffect(() => {
    fetchData();
  }, [currentPage, selectedUser]);

  return (
    <div>
      {/* Dropdown for filtering by user */}
      <select onChange={(e) => setSelectedUser(e.target.value)}>
        <option value="">All Users</option>
        <option value="1">User 1</option>
        <option value="2">User 2</option>
        <option value="3">User 3</option>
        {/* Add more options as needed */}
      </select>

      {/* Post List */}
      {posts.map((post, index) => {
        if (posts.length === index + 1) {
          return (
            <div ref={lastPostRef} key={post.id}>
              <h4>{post.title}</h4>
              <p>{post.body}</p>
            </div>
          );
        } else {
          return (
            <div key={post.id}>
              <h4>{post.title}</h4>
              <p>{post.body}</p>
            </div>
          );
        }
      })}

      {loading && <p>Loading...</p>}
    </div>
  );
};

export default Dump;
