import React, { useContext } from "react";
import "./HowItWorks.scss";
import { FaArrowRight } from "react-icons/fa";
import setting from "../../assets/favicons/settings.png";
import search from "../../assets/favicons/magnifier.png";
import server from "../../assets/favicons/server.png";
import feedback from "../../assets/favicons/feedback.png";
import { PageContext } from "../../layouts/HomePage/HomeLayout";

export default function HowItWorks() {
  const { currentPage } = useContext(PageContext);

  const data = [
    {
      image: search,
      title: "FIND CONSULTANCIES",
      description:
        " Search and filter consultancies based on location, course or university",
    },
    {
      image: server,
      title: "SHORTLIST AND SEND REQUEST",
      description:
        " Shortlist consultancies and easily send a request to your top choices.",
    },
    {
      image: feedback,
      title: "GET RESPONSE",
      description:
        " Receive responses and track your request status from the consultancies.",
    },
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <section
      className={`how-it-works ${currentPage === "browse" ? "hidden" : ""}`}
    >
      <div className="title-section">
        <div className="title">
          <img src={setting} alt="settings" />
          <div className="text">HOW IT WORKS?</div>
        </div>

        <div className="btn-find-consultancies" onClick={scrollToTop}>
          <div className="btn-text">Find Consultancies</div>
          <div className="btn-icon">
            <FaArrowRight />
          </div>
        </div>
      </div>
      <div className="steps">
        {data.map((item) => (
          <div className="step" key={item.title}>
            <img src={item.image} alt={item.title} />
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
