import React, { useState } from "react";
import { FiMapPin } from "react-icons/fi";
import "./DistrictDropdown.scss";

const DistrictDropdown = ({
  options,
  smallScreen,
  selectedDistrict,
  setSelectedDistrict,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSelectDistrict = (district) => {
    setSelectedDistrict([district]);
    setIsDropdownOpen(false);
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);

  return (
    <div className="text-2" onClick={toggleDropdown}>
      <div className="district-icon">
        <FiMapPin />
      </div>
      <div className="district-box">
        <div className="district-title">
          {smallScreen ? (
            <div className="district-title-init">Consultancy Location</div>
          ) : (
            <div className="district-title-init">
              Select Consultancy Location
            </div>
          )}
        </div>
        <div className="district">{selectedDistrict || ""}</div>

        {isDropdownOpen && (
          <div className="dropdown-menu">
            {options.length > 0 ? (
              options.map((district) => (
                <div
                  key={district}
                  className="dropdown-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectDistrict(district);
                  }}
                >
                  {district}
                </div>
              ))
            ) : (
              <div className="dropdown-item">No Districts Available</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DistrictDropdown;
