import React from "react";
import "./SkeletonLoader.scss";

export default function SkeletonLoader() {
  return (
    <div className="consultancy-detail-skeleton">
      <div className="close-box-skeleton shimmer"></div>
      <div className="header-skeleton shimmer"></div>
      <div className="statistic-skeleton">
        <div className="box-skeleton shimmer"></div>
        <div className="box-skeleton shimmer"></div>
      </div>
      <div className="description-skeleton shimmer"></div>
      <div className="destination-skeleton">
        <div className="title-skeleton shimmer"></div>
        <div className="country-list-skeleton">
          <div className="country-skeleton shimmer"></div>
          <div className="country-skeleton shimmer"></div>
          <div className="country-skeleton shimmer"></div>
          <div className="country-skeleton shimmer"></div>
        </div>
      </div>
      <div className="university-skeleton">
        <div className="title-skeleton shimmer"></div>
        <div className="pill-skeleton shimmer"></div>
        <div className="pill-skeleton shimmer"></div>
        <div className="pill-skeleton shimmer"></div>
      </div>
      <div className="course-skeleton">
        <div className="title-skeleton shimmer"></div>
        <div className="course-pill-skeleton shimmer"></div>
        <div className="course-pill-skeleton shimmer"></div>
      </div>
    </div>
  );
}
