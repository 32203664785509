import React, { useState, useEffect } from "react";
import "./Loader.scss";
import { BarLoader } from "react-spinners";

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader-text">Many Consultancy</div>
      <div className="loading-line">
        <BarLoader height={4} width={100} color="#002f61" speedMultiplier={1} />
      </div>
    </div>
  );
};

export default Loader;
