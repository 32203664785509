function createPayload(
  page,
  limit,
  selectedCountriesId,
  selectedUniversitiesId,
  selectedCoursesId,
  selectedCitiesId,
  selectedDistrict
) {
  const payload = {
    page: page,
    limit: limit,
  };

  if (selectedCountriesId && selectedCountriesId.length > 0) {
    payload.countries = selectedCountriesId;
  }

  if (selectedUniversitiesId && selectedUniversitiesId.length > 0) {
    payload.universities = selectedUniversitiesId;
  }

  if (selectedCoursesId && selectedCoursesId.length > 0) {
    payload.courses = selectedCoursesId;
  }

  if (selectedCitiesId && selectedCitiesId.length > 0) {
    payload.citites = selectedCitiesId;
  }

  if (selectedDistrict && selectedDistrict.length > 0) {
    payload.districts = selectedDistrict;
  }

  return payload;
}

export default createPayload;
