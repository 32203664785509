import React, { useContext, useEffect, useState } from "react";
import "./Browse.scss";
import ConsultancyCard from "../../components/conCard/ConsultancyCard";
import ConsultancyDetail from "../../components/conDetail/ConsultancyDetail";
import Sortlist from "../../components/sortPop/Sortlist";
import SubmitForm from "../../components/formPop/SubmitForm";
import { PageContext } from "../../layouts/HomePage/HomeLayout";
import SkeletonConsultancyCard from "../../components/ParentCard/SkeletonConsultancyCard";
import { findConsultancy } from "../../api/apiService";
import SkeletonLoader from "../../components/conDetail/SkeletonLoader";
import { toast } from "react-toastify";

export default function Browse() {
  const { sortlistedConsultancyIds, setSortlistedConsultancyIds } =
    useContext(PageContext);
  const [loadingConsultancy, setLoadingConsultancy] = useState(false);
  const { currentPage, consultancyOptions } = useContext(PageContext);
  const [selectedConsultancy, setSelectedConsultancy] = useState(null);

  const [showSortlistPopup, setShowSortlistPopup] = useState(false);
  // const [showSubmitForm, setShowSubmitForm] = useState(false);

  useEffect(() => {
    handleSelectConsultancy();
  }, [currentPage]);

  // Function to toggle the shortlist state of a consultancy
  const toggleSortlist = (event, consultancy) => {
    event.stopPropagation();
    const isAlreadySortlisted = sortlistedConsultancyIds.includes(
      consultancy._id
    );
    // Add or remove consultancy ID from the shortlisted state
    const updatedSortlist = isAlreadySortlisted
      ? sortlistedConsultancyIds.filter((id) => id !== consultancy._id)
      : [...sortlistedConsultancyIds, consultancy._id];
    setSortlistedConsultancyIds(updatedSortlist);
    console.log("Sortlisted Ids", sortlistedConsultancyIds);
    setShowSortlistPopup(true);
  };

  // for list consultancy button
  const [isPopOpen, setIsPopOpen] = useState(false);
  const openPopup = () => {
    setIsPopOpen(true);
    document.body.style.overflow = "hidden";
  };
  const closePopup = () => {
    setIsPopOpen(false);
    document.body.style.overflow = "";
  };

  // Function to check if a consultancy is shortlisted
  const isSortlisted = (consultancyId) => {
    return sortlistedConsultancyIds.includes(consultancyId);
  };

  // onselectconsultancy function here
  const handleSelectConsultancy = async (
    consultancyId = "672f396dd165c7fcb0f96528"
  ) => {
    try {
      setLoadingConsultancy(true);
      const response = await findConsultancy(
        "user/findconsultancybyid",
        consultancyId
      );
      if (response.data) {
        setSelectedConsultancy(response.data);
      } else {
        toast.error("Server Error, Please Contact Administrator.");
        return [];
      }
    } catch (err) {
      toast.error("Error Fetching Data");
    } finally {
      setLoadingConsultancy(false);
    }
  };

  return (
    <div
      className={`browse-container ${
        currentPage === "browse" ? "active" : "hidden"
      }`}
    >
      {/* Left column with consultancy cards */}
      <div className="left-column">
        {consultancyOptions.length < 1 ? (
          <>
            <SkeletonConsultancyCard />
            <SkeletonConsultancyCard />
            <SkeletonConsultancyCard />
            <SkeletonConsultancyCard />
          </>
        ) : (
          consultancyOptions.map((consultancy) => (
            <ConsultancyCard
              key={consultancy._id}
              consultancy={consultancy}
              onSelect={() => handleSelectConsultancy(consultancy._id)}
              onSortlist={(event) => toggleSortlist(event, consultancy)}
              isSortlisted={isSortlisted(consultancy._id)}
            />
          ))
        )}
      </div>
      {/* Right column with consultancy details */}
      <div
        className={`${
          loadingConsultancy || selectedConsultancy ? "active" : ""
        } right-column`}
      >
        {loadingConsultancy ? (
          <SkeletonLoader />
        ) : selectedConsultancy ? (
          <ConsultancyDetail
            consultancy={selectedConsultancy}
            onSortlist={(event) => toggleSortlist(event, selectedConsultancy)}
            isSortlisted={isSortlisted(selectedConsultancy._id)}
            closeTab={() => setSelectedConsultancy(null)}
          />
        ) : (
          <SkeletonLoader />
        )}
      </div>
      {/* Sortlist popup */}
      <Sortlist
        consultancies={consultancyOptions.filter((c) =>
          sortlistedConsultancyIds.includes(c._id)
        )} // Only show shortlisted consultancies
        show={showSortlistPopup}
        toggleSortlist={toggleSortlist}
        onClear={() => setSortlistedConsultancyIds([])}
        onSubmit={openPopup}
      />
      {/* Submit form popup */}
      {/* {showSubmitForm && ( */}
      <SubmitForm isPopOpen={isPopOpen} closePopup={closePopup} />
      {/* )} */}
    </div>
  );
}
