import React from "react";
import "./Sortlist.scss";
import sortlistFill from "../../assets/favicons/sortlist-fill.png";
import { IoCloseCircle } from "react-icons/io5";
import mcLogo from "../../assets/images/mc-logo.png";

const Sortlist = ({
  consultancies,
  show,
  onClear,
  onSubmit,
  toggleSortlist,
}) => {
  if (!show) return null;

  return (
    <div
      className={`${consultancies.length < 1 ? "hidden" : ""} sortlist-popup`}
    >
      <div className="header">
        <div className="title">
          <div className="title-box">
            <div className="text">
              <div>
                <img src={sortlistFill} alt="sortlist-fill" />
              </div>
              <div className="sub-text">Sortlisted Consultancies</div>
            </div>
            <div className="count">
              <div className="count-pill">
                {consultancies.length} Consultancy
              </div>
            </div>
          </div>
        </div>

        <div className="clear-btn">
          <button onClick={onClear}>Clear All</button>
        </div>
      </div>

      <div className="info">
        <div className="sortlist-images">
          {consultancies.map((consultancy) => (
            <div key={consultancy.id}>
              <img src={mcLogo} alt={consultancy.name} />
              <button
                className="close-btn"
                onClick={(event) => toggleSortlist(event, consultancy)}
              >
                <IoCloseCircle />
              </button>
            </div>
          ))}
        </div>
        <div className="request-btn">
          <button
            className="submit-btn"
            onClick={onSubmit}
            disabled={`${consultancies.length < 1 ? "true" : ""}`}
          >
            Request
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sortlist;
