import React, { useContext, useState } from "react";
import "./SubmitForm.scss";
import { PiBookmarkFill } from "react-icons/pi";
import { storeData } from "../../api/apiService";
import Popup from "../popup/Popup";
import { toast } from "react-toastify";
import { PageContext } from "../../layouts/HomePage/HomeLayout";

const SubmitForm = ({ closePopup, isPopOpen }) => {
  const {
    selectedUniversity,
    selectedCourse,
    selectedCountry,
    selectedCity,
    sortlistedConsultancyIds,
    setSortlistedConsultancyIds,
  } = useContext(PageContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState({});
  const [isChecked, setIsChecked] = React.useState(false);

  // Form validation
  const validate = () => {
    let tempErrors = {};

    if (!name) {
      tempErrors.name = "Consultancy name is required";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      tempErrors.email = "Email is required";
    } else if (!emailRegex.test(email)) {
      tempErrors.email = "Please enter a valid email";
    }

    const phoneRegex = /^\d{10}$/;
    if (!phoneNo) {
      tempErrors.phoneNo = "Phone number is required";
    } else if (!phoneRegex.test(phoneNo)) {
      tempErrors.phoneNo = "Please enter a valid 10-digit phone number";
    }

    if (!address) {
      tempErrors.address = "Address is required";
    }

    if (!isChecked) {
      tempErrors.checkbox = true;
    }

    setError(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // handle submit list consultancy buttons
  const handleSubmitInquiry = () => {
    if (validate()) {
      const inquiryBody = {
        name: name,
        email: email,
        phoneNo: phoneNo,
        address: address,
        message: message,
        consultancyList: sortlistedConsultancyIds,
        intrestedCountry: selectedCountry,
        intrestedCity: selectedCity,
        intrestedCourse: selectedCourse,
        intrestedUniversity: selectedUniversity,
      };

      try {
        storeData("endpoint", inquiryBody).then((res) => {
          setName("");
          setEmail("");
          setPhoneNo("");
          setAddress("");
          setMessage("");
          setSortlistedConsultancyIds([]), closePopup();
          toast.success("Successfully submitted.");
          console.log("inqury data", inquiryBody);
        });
      } catch (error) {
        closePopup();
        toast.error("Error Submitting Data.Please Try Again.");
      }
    }
  };

  return (
    <>
      <Popup isOpen={isPopOpen} onClose={closePopup}>
        <div className="student-request">
          <div className="title-section">
            <div className="title-1">
              <div className="save-icon">
                <PiBookmarkFill />
              </div>
              <div className="title-text">Submit Request</div>
            </div>
            <div className="description">
              Fill the form and submit to get response from the consultancies.
            </div>
          </div>
          <form>
            <div className="input-box">
              <label>
                Full Name <span>*</span>
                <span className="error-msg">
                  {error.name ? error.name : ""}
                </span>
              </label>
              <input
                type="text"
                placeholder="Your Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div className="input-box">
              <label>
                Email <span>*</span>
                <span className="error-msg">
                  {error.email ? error.email : ""}
                </span>
              </label>
              <input
                type="email"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="input-box">
              <label>
                Phone Number <span>*</span>
                <span className="error-msg">
                  {error.phoneNo ? error.phoneNo : ""}
                </span>
              </label>
              <input
                type="text"
                placeholder="+977 98XXXXXXXX"
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                required
              />
            </div>

            <div className="input-box">
              <label>
                Current Location <span>*</span>
                <span className="error-msg">
                  {error.address ? error.address : ""}
                </span>{" "}
              </label>
              {/* <p>To recommend you the nearest consultancies.</p> */}
              <input
                type="text"
                placeholder="Enter yout address line (Street name, City)"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>

            <div className="input-box">
              <label>
                Your Message <span>(optional)</span>
                <span className="error-msg">
                  {error.message ? error.message : ""}
                </span>
              </label>
              <p>
                Your message to us that you want to send about you or your
                interest.
              </p>
              <textarea
                type="text"
                placeholder="Message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>

            {!isChecked && error.checkbox && (
              <label>
                <span className="error-msg">
                  {error.checkbox
                    ? "Please agree to our terms and conditions"
                    : ""}
                </span>
              </label>
            )}
            <div className="terms-box">
              <input
                type="checkbox"
                name="termsCondition"
                id="termsCondition"
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <p>
                By Requesting you agreed to our{" "}
                <span>Terms and Condition.</span>
              </p>
            </div>

            <div className="button">
              <div className="cancel-btn" onClick={closePopup}>
                Cancel
              </div>
              <div className="submit-btn" onClick={handleSubmitInquiry}>
                Submit
              </div>
            </div>
          </form>
        </div>
      </Popup>
    </>
  );
};
export default SubmitForm;
