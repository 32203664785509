import React from "react";
import "./Popup.scss";
import { IoClose } from "react-icons/io5";

export default function Popup({ isOpen, onClose, children }) {
  return (
    <>
      {isOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="popup-header">
              {/* <div className="popup-title">{popupTitle}</div> */}
              <div className="popup-btn">
                <button className="close-btn" onClick={onClose}>
                  <IoClose />
                </button>
              </div>
            </div>
            <div className="popup-main">{children}</div>
          </div>
        </div>
      )}
    </>
  );
}
