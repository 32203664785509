import React, { useState, useEffect, createContext } from "react";
import Navbar from "../../section/Navbar/Navbar";
import Hero from "../../section/Hero/Hero";
import Browse from "../../section/Browse/Browse";
import HowItWorks from "../../section/HowItWorks/HowItWorks";
import Footer from "../../section/Footer/Footer";
import "./HomeLayout.scss";
import createPayload from "../../utils/createPayload";
import { getAllData, storeData } from "../../api/apiService";
import Loader from "../../components/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const PageContext = createContext();

export default function HomePageLayout() {
  const [currentPage, setCurrentPage] = useState("howitworks");
  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [university, setUniversity] = useState([]);
  const [course, setCourse] = useState([]);
  const [district, setDistrict] = useState([]);
  const [consultancyOptions, setConsultancyOptions] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [loading, setLoading] = useState(true);
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);

  const [sortlistedConsultancyIds, setSortlistedConsultancyIds] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [
          countryData,
          cityData,
          universityData,
          courseData,
          districtData,
        ] = await Promise.all([
          getAllData("admin/getcountries"),
          getAllData("admin/getcities"),
          getAllData("admin/getuniversities"),
          getAllData("admin/getcourses"),
          getAllData("user/getdistricts"),
        ]);

        setCountry(countryData.data);
        setCity(cityData.data);
        setUniversity(universityData.data);
        setCourse(courseData.data);
        setDistrict(districtData.data.data);
      } catch (error) {
        toast.err("Error getting dropdown options");
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    if (country.length > 0) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleFilterButton = async (selectedOptions, selectedDistricts) => {
    setCurrentPage("browse");
    setSelectedUniversity(selectedOptions.selectedUniversities);
    setSelectedCourse(selectedOptions.selectedCourses);
    setSelectedCountry(selectedOptions.selectedCountries);
    setSelectedCity(selectedOptions.selectedCities);
    setSelectedDistrict(selectedDistricts);

    // Reset the page and fetch consultancies based on selected filters
    setConsultancyOptions([]);

    try {
      const payload = createPayload(
        1,
        100,
        selectedOptions.selectedCountries,
        selectedOptions.selectedUniversities,
        selectedOptions.selectedCourses,
        selectedOptions.selectedCities,
        selectedDistricts
      );
      const response = await storeData("admin/getconsultancies", payload);
      if (response.status === 200) {
        setConsultancyOptions(response.data.data);
      } else {
        return [];
      }
    } catch (err) {
      toast.error("Error Fetching Data");
    }
  };

  const contextValues = {
    loading,
    setLoading,

    currentPage,
    setCurrentPage,
    country,
    setCountry,
    city,
    setCity,
    university,
    setUniversity,
    course,
    setCourse,
    district,
    setDistrict,

    // selectedOptions
    selectedUniversity,
    setSelectedUniversity,
    selectedCourse,
    setSelectedCourse,
    selectedCountry,
    setSelectedCountry,
    selectedCity,
    setSelectedCity,
    selectedDistrict,
    setSelectedDistrict,

    // consultancy
    consultancyOptions,
    setConsultancyOptions,

    sortlistedConsultancyIds,
    setSortlistedConsultancyIds,

    // FILTER
    handleFilterButton,

    isDropdownVisible,
    setIsDropdownVisible,
  };

  return (
    <>
      <div className="home-layout">
        <PageContext.Provider value={contextValues}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Navbar />
              <div
                className={`main ${
                  currentPage === "browse" ? "browse" : "howitworks"
                }`}
              >
                <Hero />
                <Browse />
                <HowItWorks />
                <Footer />
              </div>
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </>
          )}
        </PageContext.Provider>
      </div>
    </>
  );
}
